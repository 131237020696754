
import Vue from "vue";
import * as api from "@/api";
import * as api_Classes from "@/api/Classes";
import MemberFilter from "@/features/collage/memberFilter/index.vue";
import MemberList from "@/features/collage/memberList/index.vue";
import { Member, MemberQuery, Pagination } from "@/features/collage/types";
import { mockMemberList } from "@/features/collage/const";
import { Message } from "element-ui";
import { myConfirm } from "@/utils/confirm";
import { disableUser, enableUser } from "@/api";
interface IData {
  loading: boolean;
  memberList: Member[];
  total: number;
  pagination: Pagination;
  memberQuery: MemberQuery;
  selectedMembers: Member[];
  visible: boolean;
  addStudentAccount: string;
  addStudentPassword: string;
  editMember: Member | undefined;
  editStudentModalVisible: boolean;
  editStudentPhoneNumber: string;
  editStudentNickname: string;
  editStudentUsername: string;
}
enum BatchOption {
  delete,
  disable,
  enable,
  resetPassword
}
export default Vue.extend({
  name: "Persons",
  components: {
    MemberFilter,
    MemberList
  },
  data(): IData {
    return {
      loading: false,
      memberList: [],
      total: 0,
      pagination: {
        currentPage: 1,
        pageSize: 10
      },
      memberQuery: {
        search: undefined,
        skipCount: 0,
        maxResultCount: 10,
        userTypeArr: [1],
        inClass: undefined,
        sorting: undefined,
        classId: undefined
      },
      selectedMembers: [],
      visible: false,
      addStudentAccount: "",
      addStudentPassword: "Aa123456",
      editMember: undefined,
      editStudentModalVisible: false,
      editStudentPhoneNumber: "",
      editStudentNickname: "",
      editStudentUsername: ""
    };
  },
  computed: {
    multiSelected(): boolean {
      return !!this.selectedMembers.length;
    }
  },
  watch: {
    pagination: {
      handler(pagination: Pagination) {
        this.memberQuery.skipCount = (pagination.currentPage - 1) * pagination.pageSize;
        this.memberQuery.maxResultCount = pagination.pageSize;
      },
      deep: true
    },
    memberQuery: {
      handler() {
        this.fetchMemberList();
      },
      deep: true
    },
    editMember: {
      handler(val: Member) {
        if (val) {
          this.editStudentPhoneNumber = val.phoneNumber;
          this.editStudentNickname = val.nickName;
          this.editStudentUsername = val.userName;
        }
      },
      deep: true
    }
  },
  methods: {
    handleChangeMemberQuery(data: {
      isEnabled: boolean;
      nickName: string;
      phoneNumber: string;
      userName: string;
    }) {
      this.pagination.currentPage = 1;
      // TODO 筛选接口联调
      this.memberQuery = { ...this.memberQuery, ...data };
    },
    handleBatchOptionClick(e: BatchOption) {
      if (!this.multiSelected) {
        this.$message.warning("没有任何选中项");
        return;
      }
      switch (e) {
        case BatchOption.delete:
          myConfirm(this, {
            text: "确认删除所选账号？",
            resolve: async () => {
              const res = await api.delMember(this.selectedMembers.map(v => v.userId));
              if (res && !api.error(res)) {
                this.$message.success("删除成功！");
                this.fetchMemberList();
              }
            }
          });
          break;
        case BatchOption.disable:
          myConfirm(this, {
            text: "确认禁用所选账号？",
            resolve: async () => {
              const res = await disableUser(
                this.selectedMembers.filter(v => v.isEnabled).map(v => v.userId)
              );
              if (res && !api.error(res)) {
                this.memberList = this.memberList.map(v => {
                  if (this.selectedMembers.some(i => i.userId === v.userId)) {
                    return { ...v, isEnabled: false };
                  }
                  return v;
                });
                this.$message.success("禁用成功！");
              }
            }
          });
          break;
        case BatchOption.enable:
          myConfirm(this, {
            text: "确认启用所选账号？",
            resolve: async () => {
              const res = await enableUser(
                this.selectedMembers.filter(v => !v.isEnabled).map(v => v.userId)
              );
              if (res && !api.error(res)) {
                this.memberList = this.memberList.map(v => {
                  if (this.selectedMembers.some(i => i.userId === v.userId)) {
                    return { ...v, isEnabled: true };
                  }
                  return v;
                });
                this.$message.success("启用成功！");
              }
            }
          });
          break;
        case BatchOption.resetPassword:
          myConfirm(this, {
            text: "默认密码为Aa123456，确认重置密码？",
            resolve: async () => {
              const promises = this.selectedMembers.map(v => {
                return api.putMember({
                  ...v,
                  id: v.userId,
                  newPassword: "Aa123456"
                });
              });
              const res = await Promise.all(promises);
              if (res.every(v => v && !api.error(v))) {
                this.$message.success("重置成功！");
              }
            }
          });
          break;
        default:
          break;
      }
    },
    handleEditMember(data) {
      this.editMember = data;
      this.editStudentModalVisible = true;
    },
    async handleEditStudentModalConfirm() {
      if (this.editStudentUsername.length < 5) {
        Message.error("账号仅可输入5-10位数字");
        return;
      }
      const res = await api.putMember({
        ...this.editMember,
        id: this.editMember!.userId,
        userName: this.editStudentUsername,
        nickName: this.editStudentNickname,
        phoneNumber: this.editStudentPhoneNumber
      });
      if (res && !api.error(res)) {
        this.$message.success("修改成功！");
        this.fetchMemberList();
        this.editStudentModalVisible = false;
      } else {
        Message.error(res.error.message);
      }
    },
    handleDeleteMember(data: Member) {
      myConfirm(this, {
        text: `是否要删除${data.userName}`,
        resolve: async () => {
          const res = await api.delMember([data.userId]);
          if (res && !api.error(res)) {
            this.$message.success("删除成功！");
            this.fetchMemberList();
          }
        }
      });
    },
    handleResetPassword(data: Member) {
      myConfirm(this, {
        text: "默认密码为Aa123456，确认重置密码？",
        resolve: async () => {
          const res = await api.putMember({
            ...data,
            id: data.userId,
            userName: data.userName,
            newPassword: "Aa123456"
          });
          if (res && !api.error(res)) {
            this.$message.success("重置成功！");
            this.fetchMemberList();
          }
        }
      });
    },
    async handleEnableChange(e, data: Member) {
      let res;
      if (e) {
        res = await enableUser([data.userId]);
      } else {
        res = await disableUser([data.userId]);
      }
      if (res && !api.error(res)) {
        this.memberList = this.memberList.map(v => {
          if (v.userId === data.userId) {
            return { ...v, isEnabled: e };
          }
          return v;
        });
      }
    },
    async handleConfirm() {
      if (!this.addStudentAccount || !this.addStudentPassword) {
        Message.error("输入框不可为空");
        return;
      }
      if (this.addStudentAccount.length < 5) {
        Message.error("账号仅可输入5-10位数字");
        return;
      }
      if (this.addStudentPassword.length < 6) {
        Message.error("密码仅可输入6-12位字符");
        return;
      }
      const res: any = await api.putMember({
        id: "0",
        userName: this.addStudentAccount,
        newPassword: this.addStudentPassword,
        nickName: "",
        phoneNumber: "",
        isEnabled: true
      });
      if (res && !res.error) {
        this.$message.success("添加成功！");
        this.fetchMemberList();
        this.visible = false;
      } else {
        this.$message.error(res?.error?.message || "网络错误，请重试！");
      }
    },
    async handleExcelDownload() {
      const res = await api_Classes.downloadExcelTemplate();
      if (res && !api.error(res)) {
        let link = document.createElement("a");
        link.href = res.data;
        link.download = "";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        this.$message.error("网络错误，请重试！");
      }
    },
    async handleExcelImport(_file) {
      this.loading = true;
      const file = _file.raw;
      const formData = new FormData();
      formData.append("file", file);
      const res = await api_Classes.uploadExcelTemplate(formData);
      if (res && !api.error(res)) {
        const fileName = res.data;
        const res2: any = await api_Classes.importStudent({
          fileName: (fileName as unknown) as string
        });
        if (res2 && !api.error(res2)) {
          this.$message.success("批量导入学生成功");
          this.fetchMemberList();
        } else {
          this.$message.error(res2?.error?.message);
          this.loading = false;
        }
      } else {
        this.$message.error(res?.error?.message);
        this.loading = false;
      }
    },
    async fetchMemberList() {
      this.loading = true;
      var res = await api_Classes.getMemberList(JSON.parse(JSON.stringify(this.memberQuery)));
      this.loading = false;
      if (res && !api.error(res)) {
        this.memberList = (res.data.items as any) || [];
        this.total = res.data.totalCount;
      } else {
        this.memberList = mockMemberList;
        this.total = 10;
      }
    },
    handleSelect(data: Member[]) {
      this.selectedMembers = data;
    },
    async handleExportStudent() {
      const res = await api_Classes.exportStudent(this.memberQuery);
      if (res && !api.error(res)) {
        let link = document.createElement("a");
        link.href = res.data.fileName;
        link.download = "";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    addStudentClick() {
      this.addStudentAccount = "";
      this.addStudentPassword = "Aa123456";
      this.visible = true;
    }
  },
  async created() {
    this.$store.commit("breadcrumbList", [
      {
        name: "用户列表",
        path: "persons"
      }
    ]);
    this.fetchMemberList();
  }
});
